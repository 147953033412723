export const isFeatureFlagEnabled = (
  featureFlag: keyof FeatureFlags,
): boolean => {
  const featureFlags = getFeatureFlags()
  const isFound = Object.keys(featureFlags).some(
    enabledFeatureFlag => featureFlag === enabledFeatureFlag,
  )
  const isEnabled = featureFlags[featureFlag]

  return isFound && isEnabled
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeatureFlags {
  // Nuevo revamp de My Barceló
  FF_FEATURE_5847_MY_BARCELO_REVAMP: boolean
  // Poder gestionar reservas
  FF_FEATURE_5691_RESERVATION_MANAGEMENT: boolean
  // Poder abrir la modal del detalle del hotel en paso 1
  FF_FEATURE_5197_HOTEL_DETAIL_MODAL: boolean
  // Nuevo calendario con restricciones
  FF_FEATURE_5205_CALENDAR_RESTRICTIONS: boolean
}

const defaultFeatureFlags: FeatureFlags = {
  FF_FEATURE_5847_MY_BARCELO_REVAMP: true,
  FF_FEATURE_5691_RESERVATION_MANAGEMENT: true,
  FF_FEATURE_5197_HOTEL_DETAIL_MODAL: true,
  FF_FEATURE_5205_CALENDAR_RESTRICTIONS: true,
}

const getFeatureFlags: () => FeatureFlags = () =>
  process.env.NEXT_PUBLIC_FEATURE_FLAGS
    ? JSON.parse(process.env.NEXT_PUBLIC_FEATURE_FLAGS)
    : defaultFeatureFlags
