var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a82e16044ed6ea610cf68a4c42907520eadb1b12"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import packageJson from 'package.json'

const { version, name } = packageJson
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const ENV = process.env.NEXT_PUBLIC_ENV

let errorsToIgnoreFromEnvironment = []

try {
  errorsToIgnoreFromEnvironment = JSON.parse(
    process.env.NEXT_PUBLIC_SENTRY_IGNORE_ERRORS,
  ).map(error =>
    // convierte las expresiones regulares en objetos RegExp
    error.startsWith('/') ? new RegExp(error.slice(1, -1), 'i') : error,
  )
} catch (error) {
  console.error('Error parsing NEXT_PUBLIC_SENTRY_IGNORE_ERRORS', error)
}

Sentry.init({
  release: `${name}@${version}`,
  environment: ENV,
  dsn: SENTRY_DSN,
  sampleRate: 0.7,
  tracesSampleRate: 0.0,
  beforeBreadcrumb(breadcrum, hint) {
    if (breadcrum.category === 'console') {
      // Ignora algunos mensajes escritos por consola
      // breadcrum.message > string con todas las líneas del console
      // breadcrum.data.arguments Array(n) líneas con el mensaje
      // hint.level > "error"
      const messageShouldBeIgnored = consoleMessageToIgnore.some(regex =>
        regex.test(breadcrum.message),
      )
      if (messageShouldBeIgnored) {
        return null
      }
    }

    if (breadcrum.category === 'xhr' || breadcrum.category === 'fetch') {
      // Ignora algunas llamadas Ajax
      // breadcrum.data.method = "GET"
      // breadcrum.data.status_code = 200
      // breacrum.data.url: "https://..."

      const XhrShouldBeIgnored = xhrToIgnore.some(regex =>
        regex.test(breadcrum.data.url),
      )
      if (XhrShouldBeIgnored) {
        return null
      }
    }
    return breadcrum
  },
  beforeSend(event) {
    try {
      if (event.exception.values[0].value.includes('isTrusted')) {
        return null
      }
    } catch (e) {
      return event
    }

    return event
  },
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Token expired
    'Token expired while getting user data',
    /^{"name":"Unauthorized user"/,
    'Authentication error: {"error":"invalid_grant"}',
    // Lost connection errors
    'AxiosError: Request aborted',
    'AxiosError: Network error',
    // Set manually in the url a past date
    /^Error: Start date*must not be in the past.$/,
    // Trying to remove twice a button on mobile
    "Failed to execute 'removeChild' on 'Node'",
    // Error de la librería de sentry documentado aquí https://supportecommerce.barcelo.com/browse/CBE-2506
    /^TypeError Failed to fetch$/,
    /Network Error/i,
    /Fetch Error/i,
    /Load failed/i,
    /Request failed with status code/i,
    /Non-Error promise rejection/i,
    /Object captured as promise rejection/i,

    // Error de Meta: https://developers.facebook.com/community/threads/320013549791141/
    /Can't find variable: _AutofillCallbackHandler/i,

    // Error de quantum en iPhone y iPad
    // https://barcelo-hotel-group.sentry.io/issues/4832385929/?environment=production
    "null is not an object (evaluating 'b.getRootNode')",
    'ib',
    'Failed to fetch',
    'bcl is not defined',
    'bcl.u.docReady is not a function',
    /Can't find variable: bcl/i,
    'jb',
    /evaluating 'bcl.s.loadForm.props'/i,
    /evaluating 'u.matchedConfig.name'/i,
  ].concat(errorsToIgnoreFromEnvironment),
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
})

/**
 * Expresiones regulares de mensaje para no mandar a Sentry
 */
const consoleMessageToIgnore = [
  /^react-i18next::/,
  /^current URL:/, // log Adobe
  /^history change/, // log Adobe
  /^location:/, // log Adobe
]

/**
 * Expresiones regulares de url's para no mandar a Sentry
 */
const xhrToIgnore = [
  /www.google-analytics.com/,
  /doubleclick.net/,
  /tealiumiq.com/,
  /brilliantcollector.com/,
  /evergage.com/,
  /omtrdc.net/,
]
