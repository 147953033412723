import {
  isAppliedExtraPerGuest,
  isAppliedExtraPerUnit,
  isNotAppliedExtra,
  Reservation,
  ReservationRoomStay,
  ReservationSummary,
  TotalPrice,
} from 'src/core/Reservation/domain/Reservation.model'
import { CouponType } from 'src/core/Reservation/domain/CouponType'
import { isAppliedExtraPerUnitOrPerGuest } from 'src/core/Shared/domain/Extra.model'
import {
  AvailabilityCriteria,
  AvailabilityExtra,
  AvailabilityExtraPerGuest,
  AvailabilityExtraPerUnit,
} from 'src/core/Availability/domain/Availability.model'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import {
  cloneDeep,
  first,
  flattenDeep,
  isDefined,
  isEmpty,
  isEqual,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CurrencyISOCode, Price } from 'src/core/Shared/domain/Price.model'
import {
  AppliedExtraPerGuest,
  AppliedExtraPerUnit,
  NotAppliedExtra,
} from 'src/core/Shared/domain/Extra.model'
import { isCouponStaffOrFriends } from 'src/core/Hotel/domain/CouponValidation.model'
import { PromotionalCoupon } from 'src/core/Reservation/domain/ReservationCoupon'

export type CartGuaranteeType = 'all-prepay' | 'partial' | 'all-hotel'

export type ExtrasToAddIntoRoomStay =
  | ExtrasPerUnitToAddIntoRoomStay
  | ExtrasPerGuestToAddIntoRoomStay

export interface ExtrasPerUnitToAddIntoRoomStay {
  roomStay: {
    id: string
    roomId: string
    rateId: string
    mealplanId: string
  }
  extras: PendingExtraPerUnit
}

export interface ExtrasPerGuestToAddIntoRoomStay {
  roomStay: {
    id: string
    roomId: string
    rateId: string
    mealplanId: string
  }
  extras: PendingExtraPerGuest
}

export type PendingExtra =
  | PendingExtraPerUnitNotFoundInAvailability
  | PendingExtraPerGuestNotFoundInAvailability
  | PendingExtraPerUnit
  | PendingExtraPerGuest

export type PendingExtraNotFoundInAvailability =
  | PendingExtraPerUnitNotFoundInAvailability
  | PendingExtraPerGuestNotFoundInAvailability

export interface PendingExtraPerUnitNotFoundInAvailability {
  count: number
  extra: ExtraPerUnitNotFoundInAvailability
}

export interface PendingExtraPerGuestNotFoundInAvailability {
  adultsCount: number
  childrenCount: number
  extra: ExtraPerGuestNotFoundInAvailability
}

export interface ExtraPerUnitNotFoundInAvailability
  extends AvailabilityExtraPerUnit {
  notFoundInAvailability: true
  imageUrl: undefined
}

export interface ExtraPerGuestNotFoundInAvailability
  extends AvailabilityExtraPerGuest {
  notFoundInAvailability: true
  imageUrl: undefined
}

export interface PendingExtraPerUnit {
  count: number
  extra: AvailabilityExtraPerUnit
}

export interface PendingExtraPerGuest {
  adultsCount: number
  childrenCount: number
  extra: AvailabilityExtraPerGuest
}

export interface CartRoomStay extends ReservationRoomStay {
  pendingExtras?: {
    areDuplicated?: boolean
    extras?: Array<PendingExtra>
  }
}

export interface Cart {
  itineraryNumber: string
  hotelName: string
  hotelId: string
  checkIn: Date
  checkOut: Date
  occupancy: {
    adults: number
    children: number
  }
  prices: {
    total: TotalPrice
    tax: {
      original: Price
    }
    guarantee: {
      deposit: Price
      pending: Price
    }
    originalDeposit: Price
  }
  roomStays: CartRoomStay[]
  summary: ReservationSummary
  isExpired: boolean
  isGuestIdCardRequired: boolean
  guaranteedByName: boolean
  rateCurrencies: CurrencyISOCode[]
  isGuestMember: boolean
  marketCampaign: string | undefined
  customerComments?: string
}

export const isCartObsolete = (
  cart: Cart,
  availabilityCriteria: AvailabilityCriteria,
) => {
  const sameDates = cart.roomStays.every(
    ({ startDate: cartCheckIn, endDate: cartCheckOut }) => {
      const isSameCheckIn = datesManager.areDatesTheSame(
        cartCheckIn,
        availabilityCriteria.checkIn,
      )
      const isSameCheckOut = datesManager.areDatesTheSame(
        cartCheckOut,
        availabilityCriteria.checkOut,
      )

      return isSameCheckIn && isSameCheckOut
    },
  )

  if (!sameDates) {
    return true
  }

  const sameNumberOfRooms =
    cart.roomStays.length === availabilityCriteria.adults.length

  if (!sameNumberOfRooms) {
    return true
  }

  const sameCoupons = cart.roomStays.some(roomStay => {
    if (
      isUndefined(availabilityCriteria.coupons) &&
      isUndefined(roomStay.coupons)
    ) {
      return true
    }

    if (
      isUndefined(availabilityCriteria.coupons) &&
      roomStay.coupons?.some(
        coupon => coupon.type === CouponType.Promotional && coupon.applies,
      )
    ) {
      return false
    }

    if (isDefined(availabilityCriteria.coupons)) {
      return roomStay.coupons?.some(
        coupon =>
          (coupon as PromotionalCoupon)?.name ===
          first(availabilityCriteria.coupons),
      )
    }

    return true
  })

  if (!sameCoupons) {
    return true
  }

  const sameOccupancy = cart.roomStays.every(({ occupancy }, index) => {
    const childrenAges = isDefined(availabilityCriteria.childrenAges)
      ? availabilityCriteria.childrenAges[index]
      : []

    const availabilityCriteriaOccupancy: ReservationRoomStay['occupancy'] = {
      adults: availabilityCriteria.adults[index],
      children: availabilityCriteria.children[index],
      childrenAges,
    }

    return isEqual(occupancy, availabilityCriteriaOccupancy)
  })

  return !sameOccupancy
}

export const getCartGuaranteeType = (cart: Cart): CartGuaranteeType => {
  const areAllPayAtHotel = cart?.roomStays.every(
    ({ guarantee }) => !guarantee.prepay,
  )

  const totalDeposit = cart.prices.guarantee.deposit

  if (areAllPayAtHotel || totalDeposit.value === 0) {
    return 'all-hotel'
  }

  const totalPending = cart.prices.guarantee.pending
  if (totalPending.value === 0) {
    return 'all-prepay'
  }

  return 'partial'
}

const hasAllFreeCancellationPolicy = (cart: Cart) => {
  return cart.roomStays.every(
    ({ cancellation }) => cancellation.cancellationType === 'free',
  )
}

export const getEarlierCancellationDeadline = (cart: Cart) => {
  if (hasAllFreeCancellationPolicy(cart)) {
    const cancellations = cart.roomStays.map(({ cancellation }) => cancellation)

    if (cancellations.length > 0) {
      return cancellations.reduce(
        (earliestCancellation, currentCancellation) => {
          if (isUndefined(earliestCancellation.deadline)) {
            return currentCancellation
          }

          if (isUndefined(currentCancellation.deadline)) {
            return earliestCancellation
          }

          if (
            currentCancellation.deadline.isBefore(earliestCancellation.deadline)
          ) {
            return currentCancellation
          }

          return earliestCancellation
        },
      )
    }
  }
}

export const hasSomeMemberRate = (cart: Cart) => {
  return cart.roomStays.some(roomStay => roomStay.rate.isMember)
}

export const hasAllStandardRates = (cart: Cart) => {
  return cart.roomStays.every(roomStay => !roomStay.rate.isMember)
}

export const getOccupancyOptions = (
  cart: Cart,
): {
  adults: number[]
  children: number[]
  childrenAges: Array<number[]>
} =>
  cart.roomStays.reduce(
    (prevOccupancyOptions, roomStay) => ({
      adults: [...prevOccupancyOptions.adults, roomStay.occupancy.adults],
      children: [...prevOccupancyOptions.children, roomStay.occupancy.children],
      childrenAges: [
        ...prevOccupancyOptions.childrenAges,
        roomStay.occupancy.childrenAges,
      ],
    }),
    {
      adults: [] as number[],
      children: [] as number[],
      childrenAges: [] as Array<number[]>,
    },
  )

export const getTotalTaxes = (cart: Cart): Price => {
  return cart.roomStays.reduce(
    (prev, stay) => ({
      value: prev.value + stay.rate.total.taxes.price.value,
      currency: stay.rate.total.taxes.price.currency,
    }),
    { value: 0, currency: '' as CurrencyISOCode },
  )
}

export const getTotalRoomsNetPrice = (cart: Cart): Price => {
  return cart.roomStays.reduce(
    (prev, stay) => ({
      value: prev.value + stay.rate.total.netPrice.value,
      currency: stay.rate.total.netPrice.currency,
    }),
    { value: 0, currency: '' as CurrencyISOCode },
  )
}

export const hasOriginalPriceDefined = (cart: Cart) => {
  return isDefined(cart.roomStays[0].originalTotalPrice)
}

export const mapReservationIntoCart = (reservation: Reservation): Cart => {
  return {
    itineraryNumber: reservation.itineraryNumber,
    checkIn: reservation.checkIn,
    checkOut: reservation.checkOut,
    hotelName: reservation.hotel.name,
    hotelId: reservation.hotel.id,
    occupancy: {
      adults: reservation.occupancy.adults,
      children: reservation.occupancy.children,
    },
    prices: reservation.prices,
    roomStays: reservation.roomStays,
    summary: reservation.summary,
    isExpired: reservation.status !== 'Pending',
    isGuestIdCardRequired: reservation.isGuestIdCardRequired,
    guaranteedByName: reservation.guaranteedByName,
    rateCurrencies: reservation.rateCurrencies,
    isGuestMember: reservation.isGuestMember,
    marketCampaign: reservation.marketCampaign,
  }
}

export const cartHasAppliedExtras = (roomStays: ReservationRoomStay[]) =>
  roomStays.some(
    roomStay =>
      isDefined(roomStay.extras) &&
      roomStay.extras.length > 0 &&
      roomStay.extras.some(extra => extra.applied),
  )

export const getExtras = (
  roomStays: ReservationRoomStay[],
): Array<AppliedExtraPerUnit | AppliedExtraPerGuest | NotAppliedExtra> =>
  roomStays
    .flatMap(roomStay => {
      if (isDefined(roomStay.extras)) {
        return roomStay.extras
      }
    })
    .filter(isDefined)

export const getAppliedExtras = (
  roomStays: ReservationRoomStay[],
): Array<AppliedExtraPerUnit | AppliedExtraPerGuest> => {
  const extras = cloneDeep(getExtras(roomStays))
  return extras.filter(isAppliedExtraPerUnitOrPerGuest)
}

export const getNotAppliedExtras = (
  roomStays: ReservationRoomStay[],
): Array<NotAppliedExtra> => {
  const extras = getExtras(roomStays)
  return extras.filter(isNotAppliedExtra)
}

export const getGroupedAppliedExtrasById = (
  roomStays: ReservationRoomStay[],
) => {
  const appliedExtras = getAppliedExtras(roomStays)
  return appliedExtras.reduce(
    (
      acc: Array<AppliedExtraPerUnit | AppliedExtraPerGuest>,
      curr: AppliedExtraPerUnit | AppliedExtraPerGuest,
    ) => {
      const extraWithSameId = acc.find(extra => extra.id === curr.id)

      if (isUndefined(extraWithSameId)) {
        acc.push(curr)
      } else {
        if (
          isAppliedExtraPerUnit(curr) &&
          isAppliedExtraPerUnit(extraWithSameId)
        ) {
          extraWithSameId.quantity += Number(curr.quantity)
        }

        if (
          isAppliedExtraPerGuest(curr) &&
          isAppliedExtraPerGuest(extraWithSameId)
        ) {
          extraWithSameId.adultsQuantity += Number(curr.adultsQuantity)
          extraWithSameId.childrenQuantity += Number(curr.childrenQuantity)
        }

        extraWithSameId.summary = {
          netPrice: {
            ...extraWithSameId.summary.netPrice,
            value:
              extraWithSameId.summary.netPrice.value +
              curr.summary.netPrice.value,
          },
          grossPrice: {
            ...extraWithSameId.summary.grossPrice,
            value:
              extraWithSameId.summary.grossPrice.value +
              curr.summary.grossPrice.value,
          },
          tax: {
            ...extraWithSameId.summary.tax,
            amount: {
              ...extraWithSameId.summary.tax.amount,
              value:
                extraWithSameId.summary.tax.amount.value +
                curr.summary.tax.amount.value,
            },
          },
        }

        return acc
      }
      return acc
    },
    [],
  )
}

export const getRoomIds = (roomStays: ReservationRoomStay[]) =>
  roomStays.map(roomStay => roomStay.room.id)

export const getRateIds = (roomStays: ReservationRoomStay[]) =>
  roomStays.map(roomStay => roomStay.rate.id)

export const getTotalPendingExtrasPrice = (
  pendingExtras: Array<PendingExtra>,
) => {
  const sumPrice = (
    accumulator: Price,
    pendingExtra: PendingExtra | undefined,
    typeOfPrice: 'netPrice' | 'grossPrice',
  ) => {
    if (isUndefined(pendingExtra)) {
      return accumulator
    }

    if (
      isPendingExtraPerUnit(pendingExtra) ||
      isPendingExtraPerUnitNotFoundInAvailability(pendingExtra)
    ) {
      return {
        currency: pendingExtra.extra.price[typeOfPrice].currency,
        value:
          accumulator.value +
          pendingExtra.extra.price[typeOfPrice].value * pendingExtra.count,
      }
    }

    const childrenPrice = isDefined(pendingExtra.extra.childrenPrice)
      ? pendingExtra.extra.childrenPrice[typeOfPrice].value *
        pendingExtra.childrenCount
      : 0

    return {
      currency: pendingExtra.extra.adultsPrice[typeOfPrice].currency,
      value:
        accumulator.value +
        pendingExtra.extra.adultsPrice[typeOfPrice].value *
          pendingExtra.adultsCount +
        childrenPrice,
    }
  }

  const netPrice = pendingExtras.reduce(
    (accumulator, pendingExtra) => {
      return sumPrice(accumulator, pendingExtra, 'netPrice')
    },
    { value: 0 } as Price,
  )
  const grossPrice = pendingExtras.reduce(
    (accumulator, pendingExtra) => {
      return sumPrice(accumulator, pendingExtra, 'grossPrice')
    },
    { value: 0 } as Price,
  )

  return { netPrice, grossPrice }
}

export const isSomeCartAppliedExtraDifferentThanPending = (cart: Cart) => {
  const isSomeAppliedExtraDifferentThanPendingPerUnit = (
    appliedExtras: Array<AppliedExtraPerUnit | AppliedExtraPerGuest>,
    pendingExtra: PendingExtraPerUnit,
  ) => {
    return appliedExtras.some(appliedExtra => {
      if (appliedExtra.id !== pendingExtra.extra.id) {
        return true
      }

      return (
        (appliedExtra as AppliedExtraPerUnit).quantity !== pendingExtra.count
      )
    })
  }

  const isSomeAppliedExtraDifferentThanPendingPerGuest = (
    appliedExtras: Array<AppliedExtraPerUnit | AppliedExtraPerGuest>,
    pendingExtra: PendingExtraPerGuest,
  ) => {
    return appliedExtras.some(appliedExtra => {
      if (appliedExtra.id !== pendingExtra.extra.id) {
        return true
      }

      return (
        (appliedExtra as AppliedExtraPerGuest).adultsQuantity !==
          pendingExtra.adultsCount ||
        (appliedExtra as AppliedExtraPerGuest).childrenQuantity !==
          pendingExtra.childrenCount
      )
    })
  }

  return cart.roomStays.some(roomStay => {
    if (isEmpty(roomStay.pendingExtras?.extras)) {
      return true
    }

    return roomStay.pendingExtras?.extras?.some(pendingExtra => {
      const appliedExtras = roomStay.extras.filter(
        isAppliedExtraPerUnitOrPerGuest,
      )

      if (isPendingExtraNotFoundInAvailability(pendingExtra)) {
        return false
      }

      if (isEmpty(appliedExtras)) {
        return true
      }

      return isPendingExtraPerUnit(pendingExtra)
        ? isSomeAppliedExtraDifferentThanPendingPerUnit(
            appliedExtras,
            pendingExtra,
          )
        : isSomeAppliedExtraDifferentThanPendingPerGuest(
            appliedExtras,
            pendingExtra,
          )
    })
  })
}

export const hasCartPendingExtras = (cart: Cart) => {
  return cart.roomStays.some(({ pendingExtras }) => isDefined(pendingExtras))
}

const isPendingExtraNotFoundInAvailability = (
  object: PendingExtra,
): object is PendingExtraNotFoundInAvailability =>
  'notFoundInAvailability' in object.extra

export const isExtraNotFoundInAvailability = (
  object:
    | AvailabilityExtraPerUnit
    | AvailabilityExtraPerGuest
    | ExtraPerGuestNotFoundInAvailability
    | ExtraPerUnitNotFoundInAvailability,
): object is
  | ExtraPerGuestNotFoundInAvailability
  | ExtraPerUnitNotFoundInAvailability => 'notFoundInAvailability' in object

export const isPendingExtraPerUnit = (
  object: PendingExtra,
): object is PendingExtraPerUnit => 'count' in object

const isPendingExtraPerUnitNotFoundInAvailability = (
  object: PendingExtra,
): object is PendingExtraPerUnitNotFoundInAvailability => 'count' in object

const isPendingExtraPerGuest = (
  object: PendingExtra,
): object is PendingExtraPerGuest => 'adultsCount' in object

export const duplicateExtrasIntoPendingExtras = (
  cart: Cart,
  availableExtras: AvailabilityExtra[],
) => {
  const someRoomHasDuplicatedPendingExtras = cart?.roomStays.some(roomStay => {
    return (
      isDefined(roomStay.pendingExtras) && roomStay.pendingExtras.areDuplicated
    )
  })
  const someRoomHasAppliedExtra = cart?.roomStays.some(roomStay => {
    return (
      !isEmpty(roomStay.extras) && roomStay.extras.some(extra => extra.applied)
    )
  })

  const haveToDuplicateExtras =
    !someRoomHasDuplicatedPendingExtras && someRoomHasAppliedExtra
  if (!haveToDuplicateExtras) {
    return
  }

  const newCart = cloneDeep(cart)
  duplicateExtras(newCart, availableExtras)

  return newCart
}

const duplicateExtras = (cart: Cart, availableExtras: AvailabilityExtra[]) => {
  cart.roomStays.forEach(roomStay => {
    const mappedPendingExtras = roomStay.extras
      .map(appliedExtra => {
        if (!appliedExtra.applied) {
          return
        }

        const foundAvailableExtra = availableExtras.find(availableExtra => {
          return availableExtra.id === appliedExtra.id
        })
        if (isUndefined(foundAvailableExtra)) {
          if (isAppliedExtraPerGuest(appliedExtra)) {
            return {
              adultsCount: appliedExtra.adultsQuantity,
              childrenCount: appliedExtra.childrenQuantity,
              extra: transformToNotFoundPendingExtra(appliedExtra),
            } as PendingExtraPerGuestNotFoundInAvailability
          }
          return {
            count: appliedExtra.quantity,
            extra: transformToNotFoundPendingExtra(appliedExtra),
          } as PendingExtraPerUnitNotFoundInAvailability
        }

        if (isAppliedExtraPerGuest(appliedExtra)) {
          return {
            adultsCount: appliedExtra.adultsQuantity,
            childrenCount: appliedExtra.childrenQuantity,
            extra: foundAvailableExtra,
          } as PendingExtraPerGuest
        }

        return {
          count: (appliedExtra as AppliedExtraPerUnit).quantity,
          extra: foundAvailableExtra,
        } as PendingExtraPerUnit
      })
      .filter(isDefined)

    roomStay.pendingExtras = {
      areDuplicated: true,
      extras: isEmpty(mappedPendingExtras) ? undefined : mappedPendingExtras,
    }
  })
}

export const getAllPendingExtrasGroupedFromCart = (cart: Cart | undefined) => {
  const clonedCart = cloneDeep(cart)

  const allPendingExtras = flattenDeep(
    clonedCart?.roomStays
      .map(roomStay => {
        return roomStay.pendingExtras?.extras
      })
      .filter(isDefined),
  )

  return allPendingExtras.reduce(
    (acc: Array<PendingExtra>, curr: PendingExtra) => {
      const extraWithSameId = acc.find(
        pendingExtra => pendingExtra.extra.id === curr.extra.id,
      )

      if (isUndefined(extraWithSameId)) {
        acc.push(curr)
        return acc
      }

      if (
        isPendingExtraPerUnit(extraWithSameId) &&
        isPendingExtraPerUnit(curr)
      ) {
        extraWithSameId.count += curr.count
      }

      if (
        isPendingExtraPerGuest(extraWithSameId) &&
        isPendingExtraPerGuest(curr)
      ) {
        extraWithSameId.adultsCount += curr.adultsCount
        extraWithSameId.childrenCount += curr.childrenCount
      }

      return acc
    },
    [],
  )
}

export const getTotalPendingExtraCount = (
  pendingExtra: PendingExtraPerUnit | PendingExtraPerGuest,
) => {
  if (isPendingExtraPerUnit(pendingExtra)) {
    return pendingExtra.count
  }

  return pendingExtra.adultsCount + pendingExtra.childrenCount
}

const transformToNotFoundPendingExtra = (
  extra: AppliedExtraPerUnit | AppliedExtraPerGuest,
) => {
  if (isAppliedExtraPerUnit(extra)) {
    return {
      id: extra.id,
      type: extra.type,
      rates: [],
      required: extra.required,
      name: extra.name,
      description: extra.description,
      maxQuantity: 0,
      featured: false,
      price: extra.summary,
      notFoundInAvailability: true,
      imageUrl: undefined,
    } as ExtraPerUnitNotFoundInAvailability
  }
  return {
    id: extra.id,
    type: extra.type,
    rates: [],
    required: extra.required,
    name: extra.name,
    description: extra.description,
    maxQuantity: 0,
    featured: false,
    adultsPrice: extra.summary,
    childrenPrice: extra.summary,
    notFoundInAvailability: true,
    imageUrl: undefined,
  } as ExtraPerGuestNotFoundInAvailability
}

export const isThereExtrasInCart = (cart: Cart) => {
  return cart.roomStays.some(roomStay => {
    return !isEmpty(roomStay.extras)
  })
}

export type CartDiscount =
  | {
      netDiscount: Price
      grossDiscount: Price
    }
  | undefined

export const getCartDiscount = (cart: Cart | undefined): CartDiscount => {
  if (
    isDefined(cart) &&
    isDefined(cart.summary.netDiscount) &&
    isDefined(cart.summary.grossDiscount)
  ) {
    return {
      netDiscount: cart.summary.netDiscount,
      grossDiscount: cart.summary.grossDiscount,
    }
  }
}

interface ExtraTotalsWithTax {
  taxPercentage: string
  totalExtrasAmount: Price
  totalTaxesAmount: Price
}

export const getTotalExtrasWithTaxDistinct = (cart: Cart) => {
  return cart.roomStays.reduce((totals, roomStay) => {
    const onlyAppliedExtras = roomStay.extras.filter(
      isAppliedExtraPerUnitOrPerGuest,
    )

    for (const extra of onlyAppliedExtras) {
      const currentTotal = totals.find(
        total => total.taxPercentage === extra.summary.tax.percentage,
      )
      if (isUndefined(currentTotal)) {
        totals.push({
          taxPercentage: extra.summary.tax.percentage,
          totalTaxesAmount: cloneDeep(extra.summary.tax.amount),
          totalExtrasAmount: cloneDeep(extra.summary.netPrice),
        })
      } else {
        currentTotal.totalExtrasAmount.value += extra.summary.netPrice.value
        currentTotal.totalTaxesAmount.value += extra.summary.tax.amount.value
      }
    }

    return totals
  }, [] as ExtraTotalsWithTax[])
}

export const hasStaffOrFriendsCouponApplied = (cart: Cart | undefined) => {
  if (isUndefined(cart)) {
    return false
  }

  return cart.roomStays.some(roomStay => {
    return roomStay.coupons?.some(coupon => isCouponStaffOrFriends(coupon))
  })
}

export const getGuestFromFirstRoomStay = (cart: Cart) => {
  return first(cart.roomStays)?.guest
}

export const isGuestEmptyInCart = (cart: Cart | undefined) => {
  if (isUndefined(cart)) {
    return true
  }

  const guest = getGuestFromFirstRoomStay(cart)
  return (
    isUndefined(guest) ||
    isEmpty(guest.name) ||
    isEmpty(guest.email) ||
    isEmpty(guest.surname)
  )
}

export const hasDeposit = (cart: Cart) => {
  return cart.prices.guarantee.deposit.value > 0
}
