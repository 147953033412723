import { CSSProperties, FC, ReactNode } from 'react'
import styles from './Flex.module.scss'
import { classNames } from 'src/ui/utils/classnames'

import { SpaceType } from 'src/ui/styles/settings'
import { merge } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  direction?: 'row' | 'column' | 'row-reverse'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
  alignItems?: 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch'
  gap?: SpaceType
  children: ReactNode
  'data-testid'?: string
  wrap?: 'wrap' | 'nowrap'
  className?: string
  as?: 'span' | 'p' | 'div' | 'ul'
  style?: CSSProperties
}

export const Flex: FC<Props> = ({
  direction = 'row',
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  gap = 0,
  children,
  'data-testid': testId,
  className,
  as: Tag = 'div',
  style = {},
  wrap = 'nowrap',
}) => {
  const ownStyleProps = {
    '--flex-direction': direction,
    '--justify-content': justifyContent,
    '--align-items': alignItems,
    '--gap': gap && `var(--b-space-${gap})`,
    '--wrap': wrap,
  } as CSSProperties

  return (
    <Tag
      data-testid={testId}
      className={classNames(styles.flex, className)}
      style={merge(ownStyleProps, style)}
    >
      {children}
    </Tag>
  )
}
