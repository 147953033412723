import { container } from 'src/core/Shared/_di'
import { Price } from 'src/core/Shared/domain/Price.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export interface Hotel {
  id: string
  guestOptions: {
    maxGuests: number
    maxAdults: number
    maxChildren: number
    maxChildAge: number
  }
  name: string
  image: string
  destination: string
  city: string
  reviews: {
    averageRate?: string
    count?: number
    items?: Array<ReviewItem>
  }
  contact: {
    phone?: string
    email?: string
  }
  rooms: HotelRooms
  isAdultsOnly: boolean
  highlights: Highlight[] | undefined
  cityTax?: CityTax
  completeAddress: string
  map: {
    latitude: number
    longitude: number
    zoom: number
    staticMapImage: string
  }
}

export type HotelRooms = Record<HotelRoomId, HotelRoom>

type HotelRoomId = string

export interface ReviewItem {
  id: number
  username: string
  publishedDate: Date
  rating: number
  text: string
  title: string
}

export interface HotelRoom {
  description?: string
  detailedDescription?: string
  guests: number
  maxAdults: number
  maxChildren: number
  size?: {
    value: number
  }
  images: string[]
  services: Array<{
    id: string
    text: string
    icon: string
  }>
}

export interface HotelRoomSummary {
  id: string
  name: string
}

export interface NearbyHotel {
  id: string
  bestPriceAverage: { netPrice: Price; grossPrice: Price }
}

export interface CheckInCheckOutTime {
  checkInTime: string
  checkOutTime: string
}

type CityTaxIndicator = 'RPN' | 'PPN' | 'RPS' | 'PPS' | 'P3PN' | 'P3PS' | 'EXC'

export interface CityTax {
  indicator: CityTaxIndicator
  value: string
}

interface Highlight {
  title: string
  icon: string
}

export interface AwardsAndCertificates {
  title: string
  description: string
  logoPath: string
}

const getAdobePath = () => container.resolve('envManager').getBaseImagesUrl()

export const HOTEL_ROOM_DEFAULTS = () => ({
  ID: 'DEFAULT_ROOM',
  GUESTS: 0,
  MAXADULTS: 0,
  MAXCHILDREN: 0,
  SERVICES: [],
  IMAGES: [`${getAdobePath()}default-room`],
  FALLBACK_IMAGE: 'default-room',
  SERVICE_SRC: 'default-room-highlights-icon',
})

export const HOTEL_DEFAULTS = () => ({
  IMAGE: `${getAdobePath()}default-hotel`,
  FALLBACK_IMAGE: 'default-hotel',
})

export const getRoomInfoFromHotel = (
  room: HotelRoomSummary,
  hotel: Hotel,
): HotelRoom => {
  return hotel.rooms[room.id] ?? hotel.rooms[HOTEL_ROOM_DEFAULTS().ID]
}

export const hasToShowReviews = (hotel: Hotel) => {
  return (
    isDefined(hotel.reviews.averageRate) &&
    Number(hotel.reviews.averageRate) >= 4 &&
    isDefined(hotel.reviews) &&
    isDefined(hotel.reviews.items) &&
    hotel.reviews.items?.length > 0
  )
}

export type TripadvisorRankLevel = 'excellent' | 'veryGood'
export const getHotelTripadvisorRankLevel = (
  averageRate: string,
): TripadvisorRankLevel => {
  const averageRateValue = parseFloat(averageRate)
  return averageRateValue < 4.5 ? 'veryGood' : 'excellent'
}
