import {
  ComponentType,
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { container } from 'src/core/Shared/_di'
import { ParsedUrlQuery } from 'node:querystring'
import {
  isUndefined,
  omit,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { UserProvider } from 'src/ui/contexts/UserContext'
import { useAsyncMutation } from 'src/ui/hooks/useMutation'
import { useModal } from 'src/ui/hooks/useModal'
import { TransformingReservationModal } from 'src/ui/views/_components/LoginModal'
import { CurrencyISOCode } from 'src/core/Shared/domain/Price.model'

interface AuthProviderProps {
  children: ReactNode
  setIsLoading: (isLoading: boolean) => void
  OauthPKCEProvider: ComponentType<{
    authConfig: OauthPKCEConfig
    children: ReactNode
  }>
}

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
  setIsLoading,
  OauthPKCEProvider,
}) => {
  const { query, pathname, navigate } = useApplicationRouter()
  const [isTransformingToMemberRates, setIsTransformingToMemberRates] =
    useState<boolean>(false)
  const {
    showModal: showTransformingReservationModal,
    hideModal: hideTransformingReservationModal,
  } = useModal(TransformingReservationModal)

  const setPreviousQueryParams = async () => {
    const prevPathnameAndQuery = container
      .resolve('sessionStorageClient')
      .get<{ pathname: string; query: ParsedUrlQuery }>('prevPathnameAndQuery')

    if (isUndefined(prevPathnameAndQuery)) {
      return
    }

    container.resolve('sessionStorageClient').remove('prevPathnameAndQuery')
    await navigate.to(prevPathnameAndQuery.pathname, prevPathnameAndQuery.query)
    return prevPathnameAndQuery.query
  }

  const saveCurrentQueryParams = () => {
    container
      .resolve('sessionStorageClient')
      .set('prevPathnameAndQuery', { pathname, query: omit(query, ['logged']) })

    setIsLoading(true)
  }

  const handleError = async () => {
    await setPreviousQueryParams()
    setIsLoading(false)
  }

  const isBrowser = typeof window !== 'undefined'
  const isDevEnvironment = container.resolve('envManager').isApiMockingEnabled()
  const devAuthorizationEndpoint = isBrowser ? location.origin : undefined

  const onTransformToMemberReservation = async (
    token: string,
    itineraryNumber: string,
    currencyCode: CurrencyISOCode,
  ) => {
    if (
      isUndefined(itineraryNumber) ||
      isUndefined(currencyCode) ||
      isUndefined(token)
    ) {
      return
    }

    setIsTransformingToMemberRates(true)

    try {
      const isReservationTransformed = await container.resolve(
        'transformToMemberReservationAfterLogin',
      )(token, currencyCode, showTransformingReservationModal)

      if (isReservationTransformed) {
        await navigate.navigateWhenHavingUser()
      }
    } catch (error) {
      throw error
    } finally {
      hideTransformingReservationModal()
      setIsTransformingToMemberRates(false)
    }
  }

  const { performMutation: handleTransformToMemberReservation } =
    useAsyncMutation(
      'handleTransformToMemberReservation',
      onTransformToMemberReservation,
    )

  const authConfig: OauthPKCEConfig = {
    ...container.resolve('envManager').getAuthConfig(),
    postLogin: async token => {
      const { itineraryNumber } = container
        .resolve('reservationStorageRepository')
        .getReservationFields()
      const query = await setPreviousQueryParams()
      await handleTransformToMemberReservation(
        token,
        itineraryNumber,
        query?.currency as CurrencyISOCode,
      )
    },
    preLogin: saveCurrentQueryParams,
    autoLogin: false,
    onError: handleError,
    isDevEnvironment,
    devAuthorizationEndpoint,
  }

  return isBrowser ? (
    <IsTransformingToMemberRatesContext.Provider
      value={isTransformingToMemberRates}
    >
      <OauthPKCEProvider authConfig={authConfig}>
        <UserProvider setIsLoading={setIsLoading}>{children}</UserProvider>
      </OauthPKCEProvider>
    </IsTransformingToMemberRatesContext.Provider>
  ) : (
    <UserProvider setIsLoading={setIsLoading}>{children}</UserProvider>
  )
}

type OauthPKCEConfig = {
  clientId: string
  authorizationEndpoint: string
  tokenEndpoint: string
  redirectUri: string
  /**
   * Endpoint donde se va a concatenar el query param "code" al iniciar el flujo de autenticación
   */
  devAuthorizationEndpoint?: string
  /**
   * Mandar a true siempre que el msw esté activo
   */
  isDevEnvironment?: boolean
  scope?: string
  state?: string
  logoutEndpoint?: string
  logoutRedirect?: string
  preLogin?: () => void
  postLogin?: (token: string) => void
  onError?: () => void
  decodeToken?: boolean
  autoLogin?: boolean
  clearURL?: boolean
  // TODO: Remove in 2.0
  extraAuthParams?: { [key: string]: string | boolean | number }
  extraAuthParameters?: { [key: string]: string | boolean | number }
  extraTokenParameters?: { [key: string]: string | boolean | number }
  extraLogoutParameters?: { [key: string]: string | boolean | number }
}

const IsTransformingToMemberRatesContext = createContext<boolean>(false)

export const useIsTransformingToMemberRates = () =>
  useContext(IsTransformingToMemberRatesContext)
