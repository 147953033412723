import { StorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'
import { Cryptography } from 'src/core/Shared/infrastructure/cryptography'
import { Sentry } from 'src/core/Shared/infrastructure/errors/sentry'
import {
  RatePair,
  RoomMetadata,
} from 'src/core/Reservation/domain/Reservation.model'
import { WithInjectedParams } from 'src/core/Shared/_di/types'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  Availability,
  findBaseRateByItsRelated,
  findSelectedRate,
} from 'src/core/Availability/domain/Availability.model'

interface Dependencies {
  sessionStorageClient: StorageClient
  cryptography: Cryptography
  sentry: Sentry
}

export interface RatePairStorageRepository {
  saveFrom: (
    availability: Availability,
    rooms: (RoomMetadata | undefined)[],
  ) => void
  get: () => RatePair[] | undefined
  remove: () => void
  updateToMemberRate: () => void
}

export const ratePairStorageRepository: WithInjectedParams<
  RatePairStorageRepository,
  Dependencies
> = ({ sessionStorageClient }) => ({
  saveFrom: (
    availability: Availability,
    rooms: (RoomMetadata | undefined)[],
  ) => {
    const ratePair = getRatePair(availability, rooms)

    sessionStorageClient.set('ratePair', ratePair)
  },

  get: () => {
    const ratePairs = sessionStorageClient.get<RatePair[] | undefined>(
      'ratePair',
    )

    if (isUndefined(ratePairs)) {
      return
    }

    return ratePairs
  },

  remove: () => sessionStorageClient.remove('ratePair'),
  updateToMemberRate: () => {
    const ratePairs = sessionStorageClient.get<RatePair[] | undefined>(
      'ratePair',
    )

    if (isUndefined(ratePairs)) {
      return
    }

    ratePairs.forEach(ratePair => {
      ratePair.selected = 'base'
    })

    sessionStorageClient.set('ratePair', ratePairs)
  },
})

const getRatePair = (
  availability: Availability,
  rooms: (RoomMetadata | undefined)[],
): RatePair[] => {
  const ratePairs: RatePair[] = []

  rooms.forEach(room => {
    if (isUndefined(room)) {
      return
    }

    const selectedRate = findSelectedRate(
      availability,
      room.mealplan,
      room.roomName,
      room.rateId,
    )
    if (isDefined(selectedRate)) {
      if (!selectedRate.isMember && selectedRate.isRelated) {
        const rate = findBaseRateByItsRelated(
          availability,
          room.mealplan,
          room.roomName,
          selectedRate.id,
        )

        ratePairs.push({
          base: {
            id: rate?.id ?? selectedRate.id,
            isMember: rate?.isMember ?? false,
          },
          selected: 'related',
          related: {
            id: selectedRate.id,
            isMember: selectedRate.isMember,
          },
          convertedDifference: rate?.relatedRate?.convertedDifference ?? {
            value: 0,
            currency: 'USD',
          },
        })
      } else {
        ratePairs.push({
          base: {
            id: selectedRate.id,
            isMember: selectedRate.isMember,
          },
          selected: 'base',
          ...(!selectedRate.isRelated &&
            selectedRate.isMember &&
            isDefined(selectedRate.relatedRate) && {
              related: {
                id: selectedRate.relatedRate.rate.id,
                isMember: selectedRate.relatedRate.rate.isMember,
              },
              convertedDifference: selectedRate.relatedRate.convertedDifference,
            }),
        })
      }
    }
  })

  return ratePairs
}
